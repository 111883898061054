.task_calendar_admin
    .rbc-event
        z-index: 1
        .rbc-event-content
            text-overflow: initial
            white-space: initial
            .event_title
                text-overflow: ellipsis
                overflow: hidden
                white-space: nowrap
            .react-tooltip
                white-space: preserve-breaks
        .rbc-addons-dnd-resizable
            max-width: 100%
    .rbc-event:hover
        z-index: 2
    .rbc-header
        overflow: visible
        position: relative
    .modal_holidays_task_calendar_admin
        top: 60px
        right: 0px
        left: 0px
        width: 200px
        border-radius: 10px
        height: auto
        background-color: var(--whiteC)
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.19)
        border: 1px solid  var(--grayF8)
        z-index: 2
    .rbc-day-slot .rbc-event-content
        height: 1px
        flex: 0
    .rbc-month-view
        .rbc-row.rbc-month-header
            height: 40px
        .rbc-date-cell.rbc-now
            padding-top: 5px
        .rbc-date-cell
            padding-top: 3px