.auth_leftbar
    max-width: 800px
    width: 100%
    .auth_whitebox
        box-shadow: 0 2px 57px 0 rgba(0,0,0,0.14)
        background: var(--whiteC)
        border-radius: 15px
        width: 550px
        height: 785px
        padding: 42px 74px 0px 74px
.auth_logo
    width: 45px
.auth_title
    font-size: 28px
    letter-spacing: 0.4px
    margin-top: 40px
    margin-bottom: 40px
    text-align: center
.auth_footer    
    text-align: center
    margin-top: 30px
.auth_button
    height: 40px
    margin-top: 50px
    width: 100%
.authcode_form .auth_button
    margin-top: 30px
.account_created
    font-size: 20px
    letter-spacing: 0.29px
    line-height: 24px
    text-align: center
.check_mail_confirmation
    text-align: center
    letter-spacing: 0.23px
    line-height: 23px
.register_line
    box-sizing: border-box
    height: 1px
    width: 421px
    border: 1px solid #EEEEEE
    margin: 50px 0
.not_received_mail
    letter-spacing: 0.23px
    line-height: 23px
.recover_pass_button
    height: 40px
    margin-top: 60px
    width: 100%
.auth_image
    padding: 40px
@media (max-height: 855px)
    .auth_leftbar
            .auth_whitebox
                height: auto
                padding: 32px 57px 32px 57px
                .auth_title
                    font-size: 24px
                    margin: 20px 0
                .login_base_form
                    margin-top: 20px
                div
                    h1
                        font-size: 20px
                        margin-bottom: 12px
                .auth_footer
                    margin-bottom: 20px
@media (max-width: 1700px)
    .auth_leftbar
        max-width: 640px
@media (max-width: 1390px)
     .auth_leftbar
        max-width: 430px
        background: var(--whiteC)
        align-items: center
        .auth_whitebox
            box-shadow: none
            .forgot_password_login_footer_form
                flex-direction: column
                align-items: start
                row-gap: 5px
@media (max-width: 1200px)
    .auth_image
        justify-content: center
        padding: 10px
@media (max-width: 730px)
    .auth_leftbar
        display: flex
        justify-content: center
        align-items: center
        max-width: 100%
        padding: 20px 0
        .auth_whitebox
            box-shadow: 0 2px 57px 0 rgba(0,0,0,0.14)
            width: 90%
            max-width: 550px
    .auth_image
        display: none
@media (max-width: 430px)
    .auth_leftbar
         max-width: 430px
         .auth_whitebox
                padding: 52px 47px 0px 47px
                .auth_title
                    font-size: 20px
                    margin: 40px 0
                div
                    h1
                        font-size: 18px
                        margin-bottom: 22px
    .auth_image
        display: none