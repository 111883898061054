.uploadImage-normal
  display: flex
  justify-content: center
  align-items: center
  width: 15rem
  height: 130px
.boxImage-normal
  width: 15rem
  height: 130px
.boxImage-normal, .boxImage-mini
  .image
    max-width: 100%
  img
    max-width: 20rem
.blockImage
  color: var(--gray97)
.boxIcons
  color: var(--gray51)
.iconCloudUpload
  font-size: 30px
.imageLabel
  font-size: 14px
  font-weight: 400
  margin-bottom: 10px