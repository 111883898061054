.input_change_task_repository_title.rectangular_textarea_parent
    border: 1px solid transparent
    &:hover
        border: 1px solid var(--grayD6)
.input_change_task_repository_title.rectangular_textarea_parent .rectangular_textarea 
    font-size: 18px
.task_repository_description
    height: 300px
    .public-DraftEditor-content
        padding: 5px 0px
    .public-DraftStyleDefault-block
        margin: 3px 0px 0px 0px