:root
    --green43: #43BAA5
    --green21: #218679
    --green00: #00BDA4
    --greenEF: #EFF9F7
    --greenLightTransparent: rgba(0, 189, 164, 0.05)
    --gray51: #515966
    --redBA: #BA435D
    --gray8F: #8F9BA4
    --grayF2: #F2F2F2
    --grayF7: #F7F7F7
    --grayF8: #F8F8F8
    --grayD6: #d6d6d6
    --gray33: #334057
    --gray97: #979797
    --gray99: #999999
    --grayCF: #CFD6D5
    --grayEF: #EFEFEF
    --grayFB: #FBFBFB
    --grayEE: #EEEEEE
    --grayDD: #DDDDDD
    --greenED: #EDF5F5
    --gray73: #737373
    --gray36: #363B44
    --whiteC: #ffffff
    --blackC: #000000
    --fontF: Roboto, sans-serif
.items_center
    display: flex
    align-items: center
.flex_column
    display: flex
    flex-flow: column
.flex_column_end 
    @extend .flex_column
    justify-content: flex-end
.input_hidden
    width: 0
    height: 0
    border: 0
    padding: 0
    opacity: 0
    &:placeholder
        opacity: 0
textarea:focus
    border-color: var(--grayD6)
    --tw-ring-color: var(--grayD6)
.opacity_trigger
    opacity: 0
    transition: 0.3s
    &.active
        opacity: 1
.twin_elipsis
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap
.link
    color: var(--green43)
    border-bottom: 1px solid var(--green43)
    cursor: pointer
.vertical_text
    writing-mode: vertical-lr
    transform: rotate(-180deg)
.text-wrap-initial
    text-wrap: initial
.w-fill
    width: -moz-available
    width: -webkit-fill-available
    width: stretch