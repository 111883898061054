.time_control_box_value
    font-size: 30px
    font-weight: 300
.my_work_day_info
    font-size: 16px
.time_control_hour
    font-size: 38px
.time_control_hour_refresh
    position: absolute
    cursor: pointer
    top: 20px
    right: 20px
    color: var(--gray97)    
.white_box
    .user_time_control_calendar .calendar_toolbar, .rbc-toolbar
        display: none
.user_time_control_calendar
    height: 100%
    overflow: auto
    .rbc-calendar
        min-width: 1350px
    .rbc-time-view .rbc-time-header-gutter .custom_time_gutter
        color: var(--whiteC) 
    .rbc-time-view .rbc-time-header-content
        height: 190px
    .rbc-allday-cell
        .rbc-event-allday, .rbc-event-allday:focus ,.rbc-event.rbc-selected   
            background: var(--whiteC)
            color: var(--gray8F)
    .rbc-event.rbc-event-allday
        padding: 0
    .rbc-event-label
        display: none
    .rbc-day-slot .rbc-event
        border: none
        padding: 5px
        left: 2.5% !important

@media (max-width: 1770px)
    .my_work_day_wrapper
        .my_work_day_icon
                display: none
@media (max-width: 1300px)
    .my_work_day_wrapper
        flex-wrap: wrap
        & > div
            width: 50%
            &:last-of-type
                width: 100%
                border-top: 1px solid var(--grayD6)
                & > .button_primary
                    margin-top: 10px
                & > .button_red
                    margin-top: 10px
@media (max-width: 750px)
    .my_work_day_wrapper
        flex-direction: column
        & > div:last-of-type
            border-top: 0
        .my_work_day_box
            width: 100%
    .user_time_control_calendar_toolbar 
        .calendar_toolbar
            flex-wrap: wrap
        .calendar_toolbar_edit
            margin-left: 0
            margin-top: 10px
        .total_hours
            margin-left: 0