.vertical_sort_row
    height: 42px
    padding: 0 15px
    display: flex
    align-items: center
    span
        &:first-of-type
            width: 100px
    .move_icon
        cursor: move
.vertical_sort_header
    background: var(--grayF7)
    span
        font-weight: 500
.vertical_sort_list_element
    border-bottom: 1px solid var(--grayEE)
    touch-action: none
    user-select: none
    -moz-user-select: none
    -khtml-user-select: none
    -webkit-user-select: none
    -o-user-select: none
    span
        &:first-of-type
            cursor: move