.files_searchbar
    max-width: 320px
    width: 100%
.files_customselect
    width: 100%
    max-width: 175px
.tfs_breadcrumb
    height: 43px
    padding: 0 15px
.verticalSeparator
    background:  var(--grayD6)
    height: 27px
    width: 1px
    margin: 0 18px
.tfs_box
    position: relative
    .tfs_box_inner
        display: flex
        align-items: center
        flex-flow: column
        text-align: center
        box-shadow: 0 2px 7px 0 rgba(0,0,0,0.07)
        height: 120px
        width: 100%
        background:  var(--grayF8)
        border-radius: 8px
        .p-14
            width: 100%
            height: 100%
            display: flex
            align-items: center
            flex-flow: column
            text-align: center
            svg
                display: block
                color:  var(--green43)
                margin: 0 auto
            span
                margin-top: auto
    &:hover.file
        z-index: 10
        .tfs_box_inner
            position: absolute
            height: 205px
            & > .hidden
                display: block
                width: 100%
        .tfs_name
            color:  var(--green21)
            font-size: 14px
            font-weight: 500
            margin-top: auto
        .gray
            display: block
            margin-top: 5px
            font-size: 12px
            color:  var(--gray51)
            font-weight: 300
        .tfs_delete_icon
            color:  var(--redBA)
            background:  var(--whiteC)
            width: 100%
            height: 37px
            display: flex
            align-items: center
            justify-content: center
            font-size: 14px
            border-radius: 0 0 8px 8px