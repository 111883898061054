.admin_add_user
  margin-bottom: 35px
.users_company_box
  padding: 43px 49px
  margin: 30px 50px
  .users_company_container
    display: grid
    grid-template-columns: repeat(3, minmax(0, 1fr))
    margin-top: 55px
    column-gap: 35px
    row-gap: 35px
  .user_company_box
    height: 168px
    border: 1px solid #d6d6d6
    padding: 26px 32px
    .user_company_box_img
      width: 36px
      height: 36px
      margin-right: 16px
    .user_company_name
      line-height: 20px
    .user_company_surname
      line-height: 20px
    .user_company_box_email
      margin-bottom: 17px
      margin-top: 20px
  .admin_add_user_email
    max-width: 185px
  .users_company_select_type
    max-width: 130px
    input.input_field_input:nth-child(2)
      width: inherit
  .admin_pending_users_container
    max-height: 390px
    .admin_pending_user
      height: 42px
      padding: 13px 15px
.search_bar_users_company
  max-width: 330px

@media (max-width: 1600px)
  .users_company_box .users_company_container
    display: grid
    grid-template-columns: repeat(2, minmax(0, 1fr))
@media (max-width: 1400px)
  .users_company_box
    margin: 0 0 0 10px
@media (max-width: 1300px)
  .users_company_box
    .users_company_container
      grid-template-columns: repeat(1, minmax(0, 1fr))