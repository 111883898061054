.react-calendar
    width: 295px
    border: 0px
    border-radius: 16px
    box-shadow: 0 16px 34px 0 rgba(0,0,0,0.15)
    padding: 12px
    font-family: var(--fontF)
    font-weight: 400
    .react-calendar__navigation__label__labelText
        font-size: 12px
        color:  var(--green21)
        font-weight: 500
        letter-spacing: 0.17px
        text-transform: capitalize
    .react-calendar__year-view__months__month
        text-transform: capitalize
    .react-calendar__navigation
        margin: 0px
        button:enabled:hover 
            background:  var(--grayEF)
            svg
                color:  var(--green21)
    .react-calendar__navigation__arrow
        svg
            font-size: 11px
            color: #A8A8A8
    .react-calendar__viewContainer
        font-size: 11px
    .react-calendar__month-view__weekdays
        font-size: 11px
        font-weight: 400
        color:  var(--gray99)
        border-top: 1px solid  var(--grayF2)
        height: 35px
        border-bottom: 1px solid  var(--grayF2)
        align-items: center
        abbr
            text-decoration: none
    .react-calendar__tile
        color:  var(--black)
        display: flex
        justify-content: center
        align-items: center
        padding: 6px
        &:disabled
            background: none
            abbr
                background: none
                color:  var(--gray99)
            &:hover
                background: none
                abbr
                    background: none
        abbr
            height: 21px
            display: flex
            justify-content: center
            align-items: center
            padding: 1em
        &.react-calendar__month-view__days__day abbr
            padding: 0
            width: 21px
            border-radius: 50%
        &.react-calendar__month-view__days__day--neighboringMonth
            color:  var(--gray99)
        &:hover
            background: none
            abbr, &.react-calendar__decade-view__years__year, &.react-calendar__century-view__decades__decade
                background:  var(--grayD6)
        &.react-calendar__tile--active
            background: none
            abbr
                background:  var(--green43)
                padding: 0
                width: 20px
                border-radius: 50%
                color:  var(--whiteC)
            &:disabled
                abbr
                    background: none
        &.react-calendar__tile--now, &:enabled
            background: none
.react-calendar__tile.react-calendar__month-view__days__day.event-day:not(.react-calendar__month-view__days__day--neighboringMonth) .year_calendar_dot
    position: absolute
    background:  var(--green43)
    bottom: 0px
    content: " "
    height: 8px
    width: 8px
    border-radius: 50%
    left: 50%
    transform: translateX(-50%)
.react-calendar__tile.react-calendar__month-view__days__day.event-day
    position: relative
.react-calendar__tile--now.react-calendar__month-view__days__day:not(.react-calendar__tile--active) abbr
    background: rgba(67,186,165, 0.3)