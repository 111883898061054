.custom_select.for_header
    min-width: 150px
    .custom_select_border
        display: none
    .custom_select_header 
        margin: 0
        justify-content: center
    .custom_select_header_icon svg
        margin-left: 1em
    .custom_select_header_text
        margin-left: auto
        text-align: right