.twin_table
    &.empty_table .ReactVirtualized__Table__row
        border-bottom: 0
    &.scrollable .ReactVirtualized__Table__Grid
        overflow-x: auto !important
    .ReactVirtualized__Table__headerRow
        padding: 0px 7.5px
        background-color: var(--grayF7)
    .ReactVirtualized__Table__row
        padding: 0px 7.5px
        border-bottom: 1px solid var(--grayEE)
        &.row_refunded
            background-color: var(--whiteC)
    .ReactVirtualized__Table__rowColumn,.ReactVirtualized__Table__headerColumn
        padding: 0px 7.5px
    .ReactVirtualized__Table__headerColumn
        position: relative
    .table_header_separator
        opacity: 0
        background: #D1DEDC
        width: 1px
        padding: 0px 1px
        position: absolute
        top: 5px
        bottom: 5px
        right: 0
    .ReactVirtualized__Table__headerRow:hover .table_header_separator
        opacity: 1
        transition: 0.3s
.search_bar_table
    width: 320px
.table_search_modal
    position: fixed
    z-index: 9
    padding: 30px 15px
    background: var(--whiteC)
    border-radius: 10px
    .table_search_modal_button
        font-size: 12px
.twin_table.isNull
    .ReactVirtualized__Grid__innerScrollContainer
        height: 100% !important
        max-height: 100% !important
    .ReactVirtualized__Table__row
        border: 0
        height: 100% !important
    .tablecellnull
        position: absolute
        transform: translate(-50%, -50%)
        left: 50%
        top: 50%
.grid_header_table
    display: grid
    grid-auto-flow: column
    grid-gap: 10px
.custom_render_image
    height: 35px
@media (max-width: 1400px)
    .big-layout
        padding: 20px 25px
    .big_layout_title
        margin-bottom: 15px
@media (max-width: 1270px)
    .table_header
        flex-direction: column
        align-items: start
        .table_searcher_header
            margin-bottom: 20px
@media (max-width: 935px)
    .table_header
        .grid_header_table div
            .button_config_columns_modal
                flex-direction: column
                align-items: start
                row-gap: 5px
        .table_searcher_header, .search_bar_table
            width: 100%
@media (max-width: 465px)
    .table_header
        .grid_header_table
            div
                .button_config_columns_modal
                    button
                        font-size: 12px
    .big-layout
        padding: 20px