.control_employee_working_month
    .table_header
        display: none
    .calendar_toolbar
        margin-bottom: 25px
    .balance_correct
        background-color: #EFF6F6
    .balance_more_hours
        background-color: #FCF6ED
    .balance_less_hours
        background-color: #FAF2F4
    .balance_total
        padding: 10px 30px
    .balance_total_less_hours
        color: var(--redBA)
        background-color: #F3EBED
    .icon_balance_error_container
        .icon_balance_error
            opacity: 0
    .icon_balance_error_container:hover
        .icon_balance_error
            opacity: 1
            box-shadow: 0 2px 7px 0 rgba(0,0,0,0.14)
            &:before
                content: ' '
                position: absolute
                width: 0px
                height: 0px
                left: 15px
                bottom: 13px
                border-style: solid
                border-width: 8px
                border-color: transparent var(--whiteC) transparent transparent
    .time_control_employee_selector.custom_select
        .custom_select_header
            height: 24px
            margin-top: 0px
        .custom_select_border
            display: none
        .custom_select_header_icon
            svg
                margin-top: -3px
                height: 20px
    .time_control_employee_selector.custom_select.opened
        .custom_select_dropdown
            width: 120px
            right: 0
            left: auto
    .time_selector_month_calendar
        z-index: 9
        top: 35px
        left: 55px
        .react-calendar .react-calendar__tile:hover
            border-radius: 5px
.switch_custom_hours
    border: 1px solid var(--green43)
    background-color: transparent
    color: var(--green21)
    .switch_button_tab
        font-size: 16px
        font-weight: 400