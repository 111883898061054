$gridRow: 125px
.dashboard_grid
    grid-template-rows: repeat(auto-fill,minmax($gridRow,$gridRow))
    grid-auto-flow: row
    grid-auto-rows: minmax($gridRow,$gridRow)
    overflow-y: auto
.dashboard-layout
    @extend .dashboard_grid
    grid-gap: 25px
    .active
        .ghost
            background: rgba(222, 229, 233, 0.7)
            z-index: 1
            border-radius: 15px
.modal_families_dashboard
    background: var(--grayF7)
    user-select: none
    -moz-user-select: none
    -khtml-user-select: none
    -webkit-user-select: none
    -o-user-select: none
.dashboard_header_category
    border-radius: 8px
    height: 75px
    svg
        height: 25px
        margin-bottom: 5px
.dashboard_component
    .tce_employee_holidays_calendar .custom_year
        display: flex
        flex-wrap: wrap
        .calendar_month_item
            min-width: 250px
            max-width: 300px
            width: 100%