.twin_comment_textarea
    span
        display: none
    .custom_textarea
        font-size: 12px
        color: var(--gray51)
.twin_create_comment 
    .file_clip_input span
        max-width: 100px
        margin-right: 10px
    .custom_textarea_parent
        .custom_textarea,.custom_textarea &:focus
            padding: 0
            border: 1px solid transparent
.twin_comment_edit_button
    height: 26px
    padding: 0 24px
    font-size: 14px
.twin_comment_icon
    height: 19px
.twin_comment_subtitle
    min-width: 140px