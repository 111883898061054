.modal_edit_customerProject
    .single_task_inner,.single_task_header
        padding-left: 0
        padding-right: 10px
        overflow: visible
    .single_task_header
        padding-top: 0
        background: none
    .parent_task_return
        padding-left: 0
        padding-right: 0
    .modal_edit_cproject 
        .customer_project_tabs .horizontal_tabs_subtitle .horizontal_tab_item_without_link 
            width: 33.3%
        .customer_project_status_box
            min-width: 143px
            min-height: 60px
        .custom_select_multiple_image_header
            width: 190px
        .customer_project_activity_tab
            position: relative
            margin-right: -20px
            grid-template-columns: 1px auto
            grid-auto-rows: max-content
            .customer_project_activity_tab_inner
                padding-right: 20px
                .customer_project_activity_component_inner
                    z-index: 1
                    width: 34px
                    height: 34px
            .customer_project_activity_line_parent
                position: relative
                .customer_project_activity_line
                    position: absolute
                    top: 0
                    bottom: 0
                    left: 16px
                    height: 100%
                    background: #D6D6D6
                    width: 1px
                    z-index: 0
            .cp_comment_component_tab_header_icon
                height: 19px
        .modal_edit_customer_project_description span
            margin-bottom: 16px