.breadcrumb_element
    font-size: 14px
    font-weight: 300
    color: var(--gray51)
    &:last-child
        font-weight: 400
.breadcrumb_separator
    margin: 0 9px
@media (max-width: 600px)
    .breadcrumb_element
        &:not(:first-child)
            display: none
    .breadcrumb_separator
        display: none