.modal_bottom_nav_app
    width: calc(100vw - 70px)
    right: 0
    .modal_task_container
        box-shadow: 0 2px 7px 0 rgba(0,0,0,0.14)
    .modal_task_container,.modal_task,.modal_task_header
        border-top-left-radius: 20px
        border-top-right-radius: 20px
.modal_working_task_name
    text-overflow: ellipsis
    overflow: hidden  
    white-space: nowrap
.modal_task
    &.opened
        height: 85vh
        background: var(--whiteC)
.modal_task_container
    .single_task
        transition: 0.6s
    &.opened 
        transition: 0.6s
        transform: translateY(100%)
        &.active
            transform: initial
    &.skip_animation
        transition: none
.task_working_minimize_icon, .task_minimize_icon
    margin-top: -13px
.task_working_show_task
    margin-top: -5px
    svg
        width: 16px
        height: 16px