.button
  border-radius: 9999px
  transition: 0.3s
  display: flex
  align-items: center
  justify-content: center
  padding: 4px 24px
  font-size: 16px
  font-weight: 400
  &.regular14
    font-size: 14px
  &:disabled
    background: var(--grayCF)
    color: var(--whiteC)
    border-color: var(--whiteC)
.button_primary
  border: 1px solid var(--green43)
  background: var(--green43)
  color: var(--whiteC)
  .loading_spinner svg
    color: var(--whiteC)
  &:hover
    background: var(--whiteC)
    color: var(--green43)
    border-color: var(--green43)
    .loading_spinner svg
      color: var(--green43)
  &:disabled
    background: var(--grayCF)
    color: var(--whiteC)
    border-color: var(--whiteC)
.button_secondary
  color: var(--green21)
  border: 1px solid var(--green43)
  &:hover
    background: var(--green43)
    color: var(--whiteC)
.button_red
  background: var(--redBA)
  border: 1px solid var(--redBA)
  color: var(--whiteC)
.button_red_secondary
  color: var(--redBA)
  border: 1px solid var(--redBA)
  &:hover
    background: var(--redBA)
    color: var(--whiteC)
.button_info_icon:hover .button_primary_info_left
  display: flex  
.button_primary_info_left
  display: none
  background: var(--whiteC)
  z-index: 999
  position: absolute
  right: calc(100% + 10px)
  padding: 10px
  align-items: center
  justify-content: center
  box-shadow: 0px 2px 7px 2px rgba(0, 0, 0, 0.15)
  border-radius: 10px
  width: max-content
  max-width: 300px
  &:after
      content: " "
      position: absolute
      top: 50%
      left: 100%
      margin-top: -5px
      border-width: 5px
      border-style: solid
      border-color: transparent transparent transparent #fff