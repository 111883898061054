.modal_edit_campaign_customer
    .modal_edit_campaign_customer_title 
        .campaign_customer_tabs .horizontal_tabs_subtitle .horizontal_tab_item_without_link 
            width: 50%
        .campaign_customer_status_box
            min-width: 143px
            min-height: 60px
        .custom_select_multiple_image_header
            width: 190px
        .campaign_customer_activity_tab
            position: relative
            margin-right: -20px
            grid-template-columns: 1px auto
            grid-auto-rows: max-content
            .campaign_customer_activity_tab_inner
                padding-right: 20px
                .campaign_customer_activity_component_inner
                    z-index: 1
                    width: 34px
                    height: 34px
            .campaign_customer_activity_line_parent
                position: relative
                .campaign_customer_activity_line
                    position: absolute
                    top: 0
                    bottom: 0
                    left: 16px
                    height: 100%
                    background: #D6D6D6
                    width: 1px
                    z-index: 0
            .cp_comment_component_tab_header_icon
                height: 19px