.edit_bag_of_hours
    .parent_task_return
        padding-left: 0
        padding-right: 0
    .single_task_inner,.single_task_header
        padding-left: 0
        padding-right: 10px
        overflow: visible
    .single_task_header
        padding-top: 0 
        background: none
    .field_padding
        padding: 12px 8px
    .row_task
        overflow: hidden
    .row_hours
        color: var(--gray73)
        display: flex
    .row_arrow
        width: 50px
    .title_taskworklogs
        font-size: 18px
        color: var(--gray51)
        margin-bottom: 20px
    .header_table_taskworklogs
        padding: 8px
        background-color: var(--grayF7)
        font-weight: 500
        min-height: 50px
        .row_hours
            color: var(--gray51)
    .task_work_log_row
        padding: 8px
        min-height: 50px
        flex: auto
        .row_code
            color: var(--green21)
    .table_background_color
        background-color: var(--greenED)
        flex: auto
        .employee_task
            .row_employee_logs
                color: var(--gray36)
                .image_employee
                    width: 35px
                    height: 35px
    .custom_render_boolean
        .icon_not_finished
            color: var(--gray73)
        .icon_finished
            color: var(--green43)