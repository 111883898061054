.modal_config_columns_tabs .verticaldropdown_sort_list_row
    cursor: default

@media (max-width: 1100px)
    .modal_config_columns_tabs
        .config_columns_container
            flex-direction: column
            .config_columns_border
                display: none
            .config_colums_sort_list
                margin-top: 20px
                width: 100%

@media (max-width: 970px)
    .modal_config_columns_tabs .header_config_columns
        h2
            font-size: 18px
        .button_secondary
            font-size: 14px
            height: 40px
            padding: 4px 20px
@media (max-width: 800px)
    .modal_config_columns_tabs .header_config_columns
        flex-flow: column
        justify-content: flex-start
        align-items: flex-start
        .button_load_filters
            margin: 10px 0
            height: 34px