.multiple_selector
    display: flex
    .selector_column
        min-width: calc(50% - 75px)
        width: calc(50% - 75px)
        .option_selector span
                text-overflow: ellipsis
                overflow: hidden
                white-space: nowrap
    .selector
        min-height: 200px
        height: 200px
        width: 100%
        border: 1px solid  var(--grayD6)
        .option_selector
            height:  34px
            padding: 8px 0px 8px 21px
            display: flex
            justify-content: flex-start
            align-items: center
        .selector_searchbar
            border-bottom: 1px solid  var(--gray97)
            .input_field 
                height: 42px
                display: flex
                justify-content: center
                .input_with_icon
                    margin-top:10px
                .input_border
                    padding-top: 12px
                .input_field_input 
                    padding: 14px 0px
            .input_border
                border: 0
        .options_container
            padding: 9px 0px
            height: 156px
            overflow-y: auto
        .option_selector:hover
            background-color:  var(--greenEF)
.middle_icons_button_container
    width: 150px
    min-width: 150px
    display: flex
    flex-flow: column
    justify-content: center
    align-items: center
    padding-top: 60px
    .middle_icons_button
        width: 67px
        height: 47px
        display: flex
        align-items: center
        justify-content: center
        border: 1px solid  var(--grayD6)
        margin: 10px 0px
        border-radius: 5px
        cursor: pointer