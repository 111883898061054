@import 'react-big-calendar/lib/sass/styles'
.calendar_toolbar
    margin-bottom: 15px
    &.extended_margin
        margin: 0 25px 10px 25px
    .rbc-toolbar
        border: 0
        box-shadow: 0 2px 6px 0 rgba(0,0,0,0.07), 0 2px 7px 0 rgba(231,232,248,0.69)
        border-radius: 30px
        margin: 0
        div
            border-radius: 30px
            border: 0
            padding: 10px 20px
            font-size: 13px
            cursor: pointer
            &:hover
                background:  var(--whiteC)
                color:  var(--green43)
            &.active,&:focus
                background:  var(--green43)
                color:  var(--whiteC)
    .toolbar-label
        font-size: 22px
        font-weight: 500
        letter-spacing: 0.32px
.rbc-calendar
    .rbc-btn-group > div:not(:first-child):not(:last-child),.rbc-btn-group > div:first-child:not(:last-child),.rbc-btn-group > div:last-child:not(:first-child)
        border-radius:30px
    .rbc-header
        text-transform: uppercase
        font-family: var(--fontF)
        font-size: 14px
        color:  var(--gray51)
        font-weight: 300
        letter-spacing: 0.2px
        border: 0
        display: flex
        justify-content: center
        align-items: center
        .rbc-button-link
            text-transform: uppercase
    .rbc-month-view,.rbc-month-row
        border: 0
        .rbc-month-header
            height: 85px
        .rbc-day-bg
            border: 1px solid  var(--grayEF)
        .rbc-date-cell
            font-family:  var(--fontF)
            font-size: 14px
            color:  var(--gray51)
            padding-top: 16px
            padding-right: 14px
.rbc-time-view
    margin-top: 40px
    border: 0
    .rbc-time-content,.rbc-time-header-content,.rbc-day-bg + .rbc-day-bg,.rbc-day-slot,.rbc-timeslot-group
        border: 0
    .rbc-time-content > * + * > *
        border: 0
        border-left: 1px solid  var(--grayEF)
    .rbc-row.rbc-time-header-cell
        border-bottom: 1px solid  var(--grayEF)
        height: 125px
    .rbc-allday-cell .rbc-day-bg
        border-left: 1px solid  var(--grayEF)
    .rbc-time-header-cell
        height: 100%
    .rbc-allday-cell
        .rbc-row-content
            z-index: initial
    .rbc-timeslot-group
        color:  var(--gray51)
        font-size: 12px
        font-weight: 300
    .rbc-time-header-gutter
        display: flex
        align-items: flex-end
        width: 50px !important
        min-width: 50px !important
        max-width: 50px !important
        justify-content: flex-end
        .custom_time_gutter
            color:  var(--gray51)
            font-size: 12px
            font-weight: 300
            margin-bottom:20px
    .rbc-label,.rbc-time-header-content
        height: 115px
    .rbc-label
        padding-right: 10px
    .rbc-time-gutter
        width: 50px
    .week_num_day
        display: flex
        justify-content: center
        align-items: center
        border-radius: 50%
        width: 30px
        height: 30px
    .rbc-today
        .week_num_day
            background:  var(--green43)
            color:  var(--whiteC)
.rbc-month-view
    .rbc-button-link
        border-radius: 50%
        width: 30px
        height: 30px
        font-size: 14px
        font-weight: 400
    .rbc-now
        .rbc-button-link
            background:  var(--green43)
            color:  var(--whiteC)
.rbc-today
    background:  var(--whiteC)
.rbc-event.rbc-selected,.rbc-event
    background:  var(--green43)
.rbc-event:focus
    outline: none
.rbc-current-time-indicator
    display: none