.schedule_working_calendar
    .rbc-calendar
        .calendar_toolbar, .week_num_day, .rbc-current-time-indicator, .custom_time_gutter,.rbc-allday-cell
            display: none
        .rbc-time-view .rbc-time-header-content, .rbc-time-view .rbc-label
            height: 60px
        .rbc-time-view
            margin: 0
        .rbc-time-view .rbc-time-gutter
            width: 40px
        .rbc-day-slot .rbc-events-container
            margin-right: 0px
        .rbc-day-slot .rbc-event,.rbc-day-slot .rbc-event:focus
            background: var(--green43)
            border-color: var(--green43)
            outline: 0