.editcardtabs_leftbar
    max-width: 300px
    width: 100%
    background: var(--whiteC)
    padding: 46px 0 46px 48px
.hide_left_bar .editcardtabs_leftbar
    display: none   
.editcardtabs_tab
    margin: 35px 45px
    border-radius: 15px
    padding: 43px 64px
    flex: auto
    display: flex
    flex-direction: column
    overflow: auto
.ectlb_bar
    width: 2px
    height: 100%
    .ectlb_bar_bar
        background: var(--green00)
        position: relative
        transition: 0.3s
.ectlb_tabs 
    display: flex
    flex-flow: column
    grid-auto-rows: min-content
    grid-gap: 15px
    overflow: visible
.editcardtabs_tabitem
    display: flex
    align-items: center
    min-height: 31px
    padding: 0 15px
    &.active
        background: var(--greenLightTransparent)
.horizontal_tabs_subtitle 
    display: flex
    border-bottom: 1px solid var(--grayD6)
    .active
        border-bottom: 2px solid var(--green43)
@media (max-width: 1400px)
    .editcardtabs_leftbar
        padding: 25px 0 25px 28px
        max-width: 250px
        .editcardtabs_tabitem 
            font-size: 16px
    .editcardtabs_tab
        margin: 20px 25px
        padding: 25px 35px
@media (max-width: 750px)
    .horizontal_tabs_subtitle
        font-size: 15px
        flex-flow: column
        border-bottom: 0
        .horizontal_tab_item
            padding: 5px 10px
            border-left: 2px solid transparent
        .active
            border-left: 2px solid var(--green43)
            border-bottom: 0px
            background: var(--greenLightTransparent)