.custom_year
    .react-calendar
        box-shadow: none
        .react-calendar__tile.react-calendar__tile--active
            background: none
            abbr
                background: none
                padding: 0
                width: 20px
                border-radius: 50%
                color:  var(--blackC)
        .react-calendar__navigation
            pointer-events: none
        .react-calendar__navigation__arrow
            display: none
        .react-calendar__tile--now:not(.react-calendar__month-view__days__day--neighboringMonth) abbr
            background:  var(--green43)
            color: white
        .react-calendar__navigation__label__labelText
            color:  var(--gray33)
        .react-calendar__month-view__weekdays
            border-top: 1px solid  var(--grayF2)
            height: 35px
            border-bottom: 1px solid  var(--grayF2)
            align-items: center
            color:  var(--gray99)
        .react-calendar__month-view__days__day
            height: 40px
            abbr
                font-size: 11px
                line-height: 11px