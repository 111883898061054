.modal_eventyear
    width: 200px
    border-radius: 10px
    height: auto
    background-color: var(--whiteC)
    box-shadow: 0 0 7px 0 rgba(0,0,0,0.19)
    &:before
        content: ' '
        position: absolute
        width: 0px
        height: 0px
        right: calc(50% - 10px)
        border-style: solid
    &.no_arrow:before
        content: none
    &.center:before
            top: -10px
            bottom: 0px
            border-width: 0px 10px 10px 10px
            border-color: transparent transparent var(--whiteC) transparent
    &.center_inversed:before
            top: auto
            bottom: -10px
            border-style: solid
            border-width: 10px 10px 0px 10px
            border-color: var(--whiteC) transparent transparent transparent
    .modal_eventyearinner
        max-height: 250px
        overflow-y: auto