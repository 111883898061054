.tce_employee_holidays_calendar
    .right_side_calendar_toolbar,.calendar_toolbar
        display: none
    .toolbar-label
        font-size: 22px
        font-weight: 500
        letter-spacing: 0.32px
.tc_holidays_container_margin
    width: 420px
    margin-right: -20px
.tc_holidays_container_inner
    padding-right: 20px
    row-gap: 35px
    .apexcharts-graphical
        transform: translate(0)
    .tce_bag_header
        h3
            font-weight: 500
            font-size: 22px
            line-height: 24px
            max-width: 220px
        .color_dot
            height: 10px
            width: 10px
            margin-left: 10px
            border-radius: 50%
    .apexcharts-datalabel-value, .apexcharts-datalabel-label, .apexcharts-legend-text
        color: var(--gray51) !important
        font-family: 'Roboto' !important
    .apexcharts-datalabel-value
        font-size: 22px
        letter-spacing: 0.32px
        line-height: 25px
    .apexcharts-datalabel-label
        letter-spacing: 0.26px
        line-height: 21px
        font-size: 18px
        font-weight: 300 !important
    .apexcharts-legend
        width: 175px !important
        right: 15px !important
        .apexcharts-legend-series
            margin: 0 0 10px 0 !important
            display: flex
            align-items: center
            .apexcharts-legend-text
                font-size: 14px !important
                font-weight: 300 !important
                line-height: 12px
            .apexcharts-legend-marker
                margin-right: 8px

@media (max-width: 1805px)
    .tce_employee_holidays_calendar .custom_year
        display: flex
        flex-wrap: wrap
        .tce_employee_holidays_calendar .custom_year .calendar_month_item
            min-width: 250px
            max-width: 300px
            width: 100%
@media (max-width: 1400px)
    .tce_holidays
        flex-flow: column-reverse
    .tc_holidays_container_margin
        width: auto
         flex-flow: row
        padding: 0
        margin: 0
        flex: unset
        margin-bottom: 30px
        .tc_holidays_container_inner
            gap: 15px
            flex-flow: row
            flex-wrap: nowrap
            height: 220px
            padding: 10px 1px
            .tce-holiday-bag
                min-width: 300px
        .employee_holiday_bag_chart
            display: none
        .tce_bag_button
            margin-top: 10px
        .tce_bag_header
            h3
                max-width: none
            .employee_holiday_bag_info
                display: flex
                margin: 5px 0
                align-items: center
                font-size: 16px
            .tce_bag_header_side
                flex-direction: column
                align-items: start
                font-size: 16px
                span
                    margin-left: 0
                    margin-top: 5px 
            .tce_bag_header_detail
                display: none
@media (max-width: 490px)
    .tce_employee_holidays_calendar
        .custom_calendar_toolbar
            flex-wrap: wrap
            .text-green-43
                width: 100%
                margin: 10px 0