.customer_project_status_flow_card_content
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.29)
.customer_project_flow_selector.custom_select
    .custom_select_header
        height: 20px
        margin-top: 0px
    .custom_select_border
        display: none
    .custom_select_header_icon
        svg
            height: 20px
    &.opened
        .custom_select_dropdown
            width: 220px
            top: calc(100% + 10px)
            left: 0