.task_project
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.11)  
.single_task
    .header_task_icon_info 
        opacity: 0
    .header_task_icons_container> .header_task_icon_container:not(:last-child)
        margin-right: 20px
    .header_task_icon_container:hover
        .header_task_icon_info 
            bottom: 25px
            left:-10px
            opacity: 1
    .input_change_task_name.rectangular_textarea_parent,.input_change_subtask_name.rectangular_textarea_parent
        border: 1px solid transparent
        &:hover
            border: 1px solid var(--grayD6)
    .input_change_task_name.rectangular_textarea_parent .rectangular_textarea textarea
        font-size: 18px
    .input_change_subtask_name.rectangular_textarea_parent
        padding: 3px
        .rectangular_textarea 
            font-size: 14px
    .input_cal_with_hours
        background-color: var(--whiteC)
        right: 0px
.single_task_inner .input_calendar_start_end > .absolute 
    right: 0
.table_work_log_row
    &.readonly
        .input_border
            border: 0px
        svg
            display: none
    & > div
        width: 26%
        &:nth-child(2)
            width: 38%
        &:last-of-type,&:nth-child(4)
            width: 5%
    
    .input_field_label_margin .input_field_with_label
        margin-top: 0px
    .input_hour_container
        height: 34px
        .modal_input_hour
            top: 35px
.not_show_until_modal,.private_task_modal,.transform_subtask_modal
    top: 30px
    box-shadow: -3px 4px 7px 0 rgba(0,0,0,0.15)
.not_show_until_modal
    right: 0
.transform_subtask_modal
    border-radius: 8px 8px 0px 0px
    right: 0
    .input_field_with_label input
        padding-left: 10px
.task_finished_message
    background-color: rgba(33, 134, 121, 0.07)
    color: #218679
    padding: 0px 14px
    display: flex
    align-items: center
    height: 34px
    border-radius: 5px