.input_change_tchecklist_name
    min-height: 35px
    .rectangular_textarea
        padding: 4px 10px
        textarea 
            font-size: 22px
            font-weight: 400
.checklist_negative_margin
    margin: 0 -10px 0 0
    padding: 0 20px 0 0
