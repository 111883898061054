.input_hour_container
    position: relative
    display: flex
    width: 100%
    flex-direction: column
    height: 50px
    .input_hour_label
        font-size: 12px
        margin-bottom: 4px
    .modal_input_hour
        top: 50px
    .icon_change_hour
        width: 100%
.input_hour_container.input_hour_focus
    .input_hour_label
        color:  var(--green43)
    .input_border
        border-color:  var(--green43)
.input_hour_container.input_hour_error, .input_hour_container.parent_input_hour_error
    .input_hour_label
        color:  var(--redBA)
    .input_border
        border-color:  var(--redBA)
.rectangular_input_hour_container
    display: flex
    width: 100%
    align-items: center
    border: 1px solid  var(--grayD6)
    padding: 3px 10px
    .rectangular_input_hour
        width: inherit
.input_hour_error, .parent_input_hour_error
    .rectangular_input_hour_container
        border: 1px solid  var(--redBA)
.modal_input_hour
    width: 150px
    position: absolute
    justify-content: center
    top: 30px
    left: 0px
    padding: 10px 20px
    border-radius: 16.5px
    background:  var(--whiteC)
    z-index: 10
    box-shadow: 0 16px 34px 0 rgba(0,0,0,0.15)
    .icon_change_hour
        cursor: pointer
        &:hover
            color:  var(--green43)
    .time_changer_value
        margin: 0px 4px
        width: 39px
        height: 34px
        display: flex
        justify-content: center
        align-items: center
        border-radius:  3px
        cursor: pointer
        &.selected
            background:  var(--green43)
            color:  var(--whiteC)