.input_change_checklist_name
    min-height: 35px
    .rectangular_textarea 
        font-size: 22px
        font-weight: 400
.input_change_checklist_section_name
    .rectangular_textarea
        padding: 4px 10px
        textarea
            font-size: 16px
            font-weight: 400
.input_change_checklist_task_name,.input_change_checklist_section_name
    border: 1px solid transparent
    &:hover
        border: 1px solid var(--grayD6)