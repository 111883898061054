.input_searchbar_product
    margin-top: 16px
.input_searched_products
    position: absolute
    height: fit-content
    background-color:  var(--whiteC)
    box-shadow: 0 6px 14px 0 rgba(0,0,0,0.15)
    z-index: 9
    width: 388px
    .results_input_searched_products
        max-height: 100px
        overflow-y: auto
        .input_searched_product
            display: flex
            align-items: center
            padding: 0 10px
            height: 40px
        .input_searched_product:hover
            background-color:  var(--grayF7)
