.twin_editor
    border: 1px solid  var(--grayD6)
    border-radius: 15px
    display: flex
    flex-direction: column
    .rdw-editor-main
        padding-left: 15px    
    .rdw-editor-toolbar
        border: 0px
        border-bottom: 1px solid  var(--grayD6)
        background: none
        margin-bottom: 0px
        .rdw-option-wrapper
            opacity: 0.5
        .rdw-option-wrapper:hover, .rdw-dropdown-wrapper:hover, .rdw-option-active
            box-shadow: none
            opacity: 1
            cursor: pointer
        .rdw-dropdown-wrapper, .rdw-option-wrapper
            border: 0px
            border-radius: 0px
    .rdw-link-modal, .rdw-embedded-modal, .rdw-emoji-modal, .rdw-colorpicker-modal, .rdw-image-modal
        height: 245px
        border-radius: 15px
    .rdw-image-modal-btn, .rdw-embedded-modal-btn, .rdw-link-modal-btn
        border: 1px solid  var(--green43)
        background:  var(--green43)
        color:  var(--whiteC)
        border-radius: 15px
        &:hover
            background:  var(--whiteC)
            color:  var(--green43)
            border-color:  var(--green43)
    .rdw-image-modal-btn:disabled, .rdw-embedded-modal-btn:disabled, .rdw-link-modal-btn:disabled
        color:  var(--green21)
        border: 1px solid  var(--green43)
        background-color:  var(--whiteC)
        &:hover
            background:  var(--green43)
            color:  var(--whiteC)
    .rdw-emoji-modal
        width: 185px
.show_code_textarea
    flex: 1 1 auto
    resize: none
    border-radius: 15px
    border: 1px solid  var(--grayD6)
    margin-top: 20px
    overflow-y: auto
    width: 100%
.show_code
    opacity: 0.5
.show_code:hover, .show_code_active
    opacity: 1
    cursor: pointer
.twin_editor_code
    margin-bottom: 6px
    display: flex
    justify-content: center
    align-items: center
    margin-left: 7.5px
.show_icon
    color:  var(--blackC)
    height: 16px
.notSelectable
    -webkit-touch-callout: none
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
.rdw-editor-main.h-100
    height: 100px
.rdw-editor-main.h-200
    height: 200px
.rdw-editor-main.h-300
    height: 300px
.twin_editor_readOnly .twin_editor .rdw-editor-toolbar
    display: none