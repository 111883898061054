.modules_dashboard
    padding: 70px 280px
    overflow: auto
    .description-text
        max-width: 900px
        display: block
        margin: 0 auto
    .searchbar
        max-width: 500px
        margin: 0 auto
        margin-bottom: 70px
    .module_listing_inner
        gap: 41px 33px
        margin-top: 40px
        .individual_module
            cursor: pointer
            padding: 25px
            padding-bottom: 10px
            height: 200px
            text-align: center
    .module_ball
        top: -10px
        right: -10px
        height: 46px
        width: 46px
        border-radius: 100%
    .module_cart
        position: fixed
        top: 150px
        right: 200px
        z-index: 10
        .module_individual
            border-bottom: 1px solid var(--grayD6)
            &.deleted
                background: var(--grayF7)
        .small_ball
            border-radius: 50%
            width: 39px
            height: 39px
            position: absolute
            top: -10px
            right: -10px
            border: 2px solid var(--whiteC)
            font-size: 18px
        .big_ball 
            height: 87px
            width: 87px
            border-radius: 50%
            box-shadow: 0 2px 8px 0 rgba(0,0,0,0.24)
        .module_cart_inner_listing 
            overflow: auto
            margin: 0 -20px
            padding: 0 20px
            max-height: 300px
        .module_cart_inner
            top: calc(100% + 15px)
            background: var(--whiteC)
            width: 500px
            right: 0
            padding: 30px 28px
            z-index: 10
            border-radius: 10px
            box-shadow: -3px 4px 7px 0 rgba(0,0,0,0.15)
            &:after
                border-bottom-color: var(--whiteC)
                border-bottom-style: solid
                border-bottom-width: 10px
                border-left: 12px solid transparent
                border-right: 12px solid transparent
                top: -10px
                left: 454px
                margin-left: -8px
                content: ""
                width: 0
                height: 0
                position: absolute