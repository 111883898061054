.switch_light_parent
    span
        height: 19px
        margin-left: 22px
    .switch_light
        background:  var(--whiteC)
        width: 45px
        height: 26px
        box-shadow: 0 1px 5px 0 rgba(0,0,0,0.12)
        border-radius: 20px
        padding: 0 3px
        &.active .ball
            margin-left: 19px
            background:  var(--green43)
        .ball
            transition: 0.3s
            width: 20px
            height: 20px
            border-radius: 100%
            background:  var(--grayD6)
            box-shadow: 0 2px 7px 0 rgba(231,232,248,0.69)