.modal_medium
    .schedule_day
        padding: 22px 0
        border-bottom: 1px solid var(--grayEE)
    .table_campaign_status_row > div
        width: 15%
        margin-right: 20px
        &:last-of-type
            width: 10%
            margin-right: 0px
        &:nth-child(2)
            width: 60%
.table_campaign_status_row
    height: 42px
    padding: 0 15px
    display: flex
    align-items: center
    border-bottom: 1px solid var(--grayEE)