@font-face
  font-family: "Roboto"
  src: url("./utils/fonts/Roboto-Bold.ttf") format("truetype")
  font-weight: 700
@font-face
  font-family: "Roboto"
  src: url("./utils/fonts/Roboto-Regular.ttf") format("truetype")
  font-weight: 400
@font-face
  font-family: "Roboto"
  src: url("./utils/fonts/Roboto-Light.ttf") format("truetype")
  font-weight: 300
@font-face
  font-family: "Roboto"
  src: url("./utils/fonts/Roboto-Medium.ttf") format("truetype")
  font-weight: 500
*
  scrollbar-width: thin
*::-webkit-scrollbar
  width: 7px
  height: 7px
::-webkit-scrollbar-thumb
  background-color: #d0cccc