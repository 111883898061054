.header
  min-height: 70px
  height: 70px
  border-bottom: solid 2px #DEE5E9
  align-items: center
  background: var(--grayFB)
  padding: 0 35px
@media (max-width: 1400px)
    .header
        padding: 0px 25px
        height: 55px
        min-height: 55px