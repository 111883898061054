.user_alerts_icons
  font-size: 24px
  cursor: pointer
  opacity: .5
.notifications
  position: relative
  margin-top: 6px
  .notifications_item
    position: absolute
    top: -14px
    height: 6px
    width: 6px
    background: linear-gradient(23.73deg, #27BC8C 0%, #5FDDDD 100%)
.logo_user
  height: 36px
  width: 36px
  position: relative
  img
    top: 5px
    position: absolute