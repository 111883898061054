.select_company_container
    display: flex
    justify-content: center
    flex: 1 1 auto
    padding: 100px 0px
.select_company
    display: flex
    flex-direction: column
    .select_company_button
        margin: 0 auto
    .container_companies
        flex: 1 1 auto
        height: 1px
    .select_company_companies_invited
        margin-bottom: 20px
        margin-top: 63px
.select_company_grid
    grid-row-gap: 40px
    grid-column-gap: 40px
    .company_box
        padding: 23px 38px
        height: 228px
        .name_company_box
            line-height: 21px
            letter-spacing: 0.26px
            height: 42px
        .address_company_box
            line-height: 16px