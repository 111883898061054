.generate_time_control_qr
    margin: 0 -30px
    .qr_container
        .overflow-auto
            padding: 0 30px
@media (max-width: 600px)
    .generate_time_control_qr
        .title_message_qr
            font-size: 17px
        .explanation_message_qr
            font-size: 14px
            margin-top: 25px
        .qr_container
            margin-top: 25px
            & > div
                font-size: 14px
            .qr_for_punchin
                height: 200px
                width: 200px
            .overflow-auto > .mt-50
                margin-top: 25px
@media (max-width: 375px)
    .generate_time_control_qr
        .qr_container
            .qr_for_punchin
                height: 150px
                width: 150px
@media (max-height: 800px)
    .generate_time_control_qr
        .explanation_message_qr
            margin-top: 30px
        .qr_container
            margin-top: 30px
            .qr_for_punchin
                height: 240px
                width: 240px
@media (max-height: 700px)
    .generate_time_control_qr
        .title_message_qr
            font-size: 17px
        .explanation_message_qr
            font-size: 13px
            margin-top: 20px
        .qr_container
            margin-top: 20px
            & > div
                font-size: 13px
            .qr_for_punchin
                height: 200px
                width: 200px
@media (max-height: 600px)
    .generate_time_control_qr
        .explanation_message_qr
            margin-top: 15px
            font-size: 13px
        .qr_container
            margin-top: 15px
            & > div
                font-size: 13px
            .qr_for_punchin
                height: 170px
                width: 170px
@media (max-height: 535px)
    .generate_time_control_qr
        .qr_container
            .qr_for_punchin
                height: 120px
                width: 120px