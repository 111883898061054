.input_field
  height: 34px
  display: flex
  flex-flow: column
  justify-content: flex-end
  align-items: initial
  input
    padding: 0
    height: 19px
  svg
    margin-right: 10px
  .input_border
    padding-top: 8px
    width: 100%
    border-bottom: 1px solid  var(--gray97)
.input_field_label_margin .input_field_with_label
  margin-top: 16px
.input_field_with_label
  display: flex
  flex-flow: column
  justify-content: flex-end
  align-items: initial
  .input_field_input
    height: 0px
    transition: 0.3s
    padding: 0px
    &:focus,&.input_label_up
      height: 19px
      & ~ .input_field_label
        font-size: 12px
        margin-bottom: 4px
    &:focus ~ .input_field_label
      color:  var(--green43)
.input_focus 
  .input_border
    border-color:  var(--green43)
  & ~ .input_border
    border-color:  var(--green43)
  label
    color:  var(--green43)
.input_field_label 
  order: -1
  font-size: 14px
  font-weight: 300
  transition: 0.3s
  color:  var(--gray51)
  height: 19px
.input_error, .input_error .input_border, .input_error + .input_border
  border-color:  var(--redBA) !important
  & ~ .input_field_label
    color:  var(--redBA) !important
  label
    color:  var(--redBA) !important
.input_field_checkbox
  input
    margin-right: 8px
.input_with_icon
  height: 19px
.placeholder-font-light::placeholder
  font-weight: 300
.input_radio
  color:  var(--green43)
  cursor: pointer
  --tw-ring-color: var(--green43) !important
  transition: 0.3s
[type="radio"]
  border-color:  var(--grayDD)
  width: 18px
  height: 18px
[type="radio"]:checked
  margin-left: 5px
  margin-right: 5px
  background-image: none !important
  --tw-ring-offset-color: var(--whiteC) !important
  --tw-ring-offset-width: 4px !important
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow) !important
  width: 8px
  height: 8px
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important

.rectangular_readonly
    background-color:  var(--grayEF)