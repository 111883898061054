.menu_bar_logo
  width: 45px
  height: 45px
.menu_icon_colorbar
  width: 4px
  position: relative
  .icon_colorbar_bar
    position: relative
    transition: 0.3s
    height: 43px
    width: 100%
    background: var(--green00)
.menu_icon_item
  padding: 8px 0
  opacity: 0.5
  justify-content: center
  &.active
    opacity: 1 
  svg
    height: 27px
.menu_icon_wrapper
  margin-left: 14px
.second_left_menu
  width: 240px
  padding: 27px 15px 27px 17px
  .secondary_menu_active
    width: 2px
    .secondary_menu_bar
      position: relative
      background: var(--green00)
      transition: 0.3s
      height: 0
      &.active
        height: 14px
.dropdown_secondary_menu_items
  display: grid
  row-gap: 6px
  & > *
    display: block
    line-height: 12px
    padding: 2px 0
    letter-spacing: 0.20px
@media (max-width: 750px)
  .second_left_menu
    position: fixed
    left: 70px
    width: calc(100% - 70px)
    height: 100%
    z-index: 1