.custom_textarea_parent
    span
        color:  var(--gray51)
        font-size: 12px
        font-weight: 300
        height: 19px
        margin-bottom: 4px
    .custom_textarea
        padding: 0
        padding-bottom: 4px
        border: 0
        border-bottom: 1px solid  var(--gray97)
        border-radius: 0
        resize: none
        width: 100%
        &:focus
            border-color:  var(--gray97)
            --tw-ring-inset: 0
.rectangular_textarea
    padding: 4px 6px
    &:focus
        border-color:  var(--gray97)
        --tw-ring-inset: 0
    textarea:focus
        border: none
        --tw-ring-inset: 0