.task_create_withhelper
    .custom_render_task_name_tooltip
        max-width: 300px
    .task_helper_form .form_title
        margin-bottom: 10px
    .single_task_inner
        padding: 0
        padding-right: 10px
        overflow: visible
    .parent_task_return
        padding-left: 0
        padding-right: 0