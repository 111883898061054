.custom_select
    position: relative
    text-align: left
    &.custom_select_error .custom_select_header 
        .custom_select_border
            border-bottom-color: var(--redBA)
        label
            color: var(--redBA)
    & .custom_select_header
        margin-top: 16px
    label
        height: 19px
        font-weight: 300
    .custom_select_header
        display: flex
        flex-flow: column
        justify-content: flex-end
        height: 34px
        align-items: initial
        width: 100%
        .custom_select_header_icon
            display: flex
            align-items: center
            width: 100%
            height: 19px
        .custom_select_border
            padding-top: 8px
            width: 100%
            border-bottom: 1px solid var(--gray97)
        .custom_select_header_text
            flex: 1 1 auto
            width: 1px
            height: 19px
            display: flex
            flex-flow: column
            justify-content: flex-end
        .custom_select_item_text
            opacity: 0
            height: 0px
            transition: 0.3s
            text-overflow: ellipsis
            overflow: hidden
            white-space: nowrap
    .custom_select_dropdown
        z-index: 10
        position: absolute
        top: calc(100% + 5px)
        left: 0
        right: 0
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.14)
        border-radius: 3px
        .custom_select_value span
            text-overflow: ellipsis
            overflow: hidden
            white-space: nowrap
    &.opened
        .custom_select_border
            border-bottom-color: var(--green43)
        label
            color: var(--green43)
    &.custom_select_label_up
        .custom_select_item_text
            min-height: 19px
            height: 19px
            opacity: 1
        label
            font-size: 12px
            margin-bottom: 4px
    .custom_select_search_bar
        background-color:  var(--whiteC)
        .input_field
            .input_field_input
                font-weight: 300
                font-size: 12px
                padding: 0 15px
            .input_border
                border-color:  var(--grayEE)
    &.no_box_custom_select
        .custom_select_header 
            margin-top: 0px
            .custom_select_header_text
                padding-left: 10px
        .custom_select_dropdown
            top: calc(100%)