.verticaldropdown_sort_list_header
    height: 42px
    padding: 0 15px
    display: flex
    align-items: center
    background: var(--grayF7)
    margin-bottom: 3px
    .move_icon
        cursor: move
    span
        font-size: 16px
.verticaldropdown_sort_component
    padding: 0 15px
    background: var(--whiteC)