.modal_task_round_image
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.11)
    left: -150px 
    z-index: 99
    border-radius: 8px 8px 0px 0px
    .custom_select_label_up label
        display: none
.custom_render_task_round_image
    .custom_select.opened
        .custom_select_dropdown
            width: 200px
            left: -155px