.sue_file
    height: 123px
    width: 195px
    border-radius: 8px
    background-color: var(--grayFB)
    box-shadow: 0 2px 7px 0 rgba(0,0,0,0.07)
    margin-right: 50px
    cursor: pointer
    padding: 14px
    display: flex
    flex-direction: column
@media (max-width: 720px)
    .sue_attached_files
        display: grid
        grid-template-columns: repeat(2, minmax(0, 1fr))
        gap: 15px
@media (max-width: 650px)
    .sue_attached_files
         grid-template-columns: repeat(1, minmax(0, 1fr))
