.color_checkbox_grey
  border-color: var(--grayDD)
.checkbox_margin
  height: 34px
  margin-top: auto
.inputcheckbox_checkbox
  width: 18px
  height: 18px
.input_field_checkbox span
  line-height: 18px
  transform: translate(0, 0%)