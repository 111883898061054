.edit_modal_button
    padding: 4px 22px
    margin-top: 68px
    position: absolute
    right: 75px
.company_info_item_title
    line-height: 14px
.company_info_item_text
    line-height: 16px
.company_information
    overflow: auto
    max-width: 547px
    padding: 32px 42px
    height: 100%
    display: flex
    flex-direction: column
.image_div_company_information
    height: 148px
.image_company_information
  max-width: 148px
  max-height: 148px
  margin-right: 75px

@media (max-width: 1060px)
    .company_information
        .company_info_section
            display: flex
            flex-flow: column
@media (max-width: 940px)
    .image_div_company_information
        flex-flow: column
        margin-bottom: 20px
        .image_company_information
            margin: auto