.dark 
    color: var(--whiteC)
    .header
        background: #2B2A33
        color: var(--whiteC)
        border-bottom-color: #131216
        .breadcrumb_element
            color: var(--whiteC)
    .app_right_side, .bg-gray-F7, .bg-gray-EF, .searched_input_products
        background: #42404F
    .rectangular_readonly
        background: none
    .editcardtabs_leftbar, .modal_tabs .editcardtabs_leftbar
        background: #26252D
        color: var(--whiteC)
    .white_box
        background: #393743
        color: var(--whiteC)
    .input_field_label
        color: var(--whiteC)
    .twin_table .ReactVirtualized__Table__headerRow, .vertical_sort_header
        background: #26252D
        color: #fff
        .bg-gray-F7
            background: #26252D
    .bg-white
        background: #393743
    .bg-green-F2
        background: #393743
    .bg-green-DB
        background: #4E4C58
    .table_search_modal
        background: #4E4C58
    .searched_input_products .results_searched_products .searched_product:hover
        background: #4E4C58
    .custom_textarea_parent .custom_textarea
        background: transparent
    .modal_input_hour, .user_time_control_calendar .rbc-allday-cell .rbc-event-allday,.rbc-today, .rbc-calendar .rbc-header, .user_time_control_calendar .rbc-allday-cell .rbc-event.rbc-selected,.single_task .input_change_task_name.rectangular_textarea_parent .rectangular_textarea textarea,.rectangular_textarea_parent .rectangular_textarea textarea
        background: #393743
        color: var(--whiteC)
    .text-gray-51,.text-gray-33,.label12,.link12
        color: var(--whiteC)
    .custom_select .custom_select_search_bar,.single_task .input_cal_with_hours,.react-calendar
        background: #393743
        color: var(--whiteC)
    .react-calendar .react-calendar__navigation button:enabled,.react-calendar .react-calendar__navigation button:enabled:hover
        background: #393743
    .modal_task.opened
        background: #393743