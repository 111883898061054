.doubleTable
    grid-gap: 38px
    grid-template-columns: 1fr 0fr
    .ReactVirtualized__Table__row
        max-width: 100%
    & > div
        padding: 0
    &.active
        grid-template-columns: 1fr 1fr
        animation-name: expandGrid
        animation-duration: 0.3s
        animation-fill-mode: forwards
        & > div:nth-child(2)
            animation: hideItem 0.3s
        .search_bar_table
            width: 220px

@keyframes expandGrid
    0%
        grid-template-columns: 1fr 0fr
    100%
        grid-template-columns: 1fr 1fr
        
@keyframes hideItem
    0%
        width: 0px
        opacity: 0
    90%
        width: 0px
        opacity: 0
    91%
        width: 100%
        opacity: 0
    100%
        width: 100%
        opacity: 1
    
@media (max-width: 1400px)
    .doubleTable
        padding: 20px 25px
    .big_layout_title
        margin-bottom: 15px
        h1
            font-size: 20px
        .button_primary
            padding: 4px 20px
            font-size: 14px