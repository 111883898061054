.selected_project_simple
    height: 41px
.searcher_customer_project_simple_label
    margin-bottom: 2px
    display: block
.searcher_customer_project_simple
    border: 1px solid  var(--grayF8)
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.24)
    .input_border
        border-bottom: 0px
    input
        padding-left: 15px
    .searcher_project_simple_container
        .custom_select_dropdown
            position: initial !important
            flex: 1 1 auto
            width: 1px
        .ReactVirtualized__Grid__innerScrollContainer
            max-width: 100% !important
        .ReactVirtualized__List
            position: absolute !important
            width: auto !important
            left: 0
            right: 0
            top: calc(100%)
            z-index: 10
        .arrow_right
            border-top: 15px solid transparent
            border-left: 15px solid  var(--grayF8)
            border-bottom: 20px solid transparent
            position: absolute