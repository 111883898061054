.taskDoubleTable
    grid-gap: 20px
    grid-template-columns: 1fr 0fr
    .ReactVirtualized__Table__row
        max-width: 100%
    .load_filters_button_icon
        display: none
    &.taskTableTab .task_table_title
        max-width: 450px
    &.active
        grid-template-columns: minmax(600px, 6fr) minmax(530px, 5fr)
        animation-name: expandGrid
        animation-duration: 0.3s
        & > div:nth-child(2)
            animation: hideItem 0.3s
        .search_bar_table
            width: 220px
        .load_button_filter > .button_secondary 
            .load_filters_button_text
                display: none
            .load_filters_button_icon
                display: block
                margin-right: 8px
        .load_columns_button_text
            display: none
    .ReactVirtualized__Grid__innerScrollContainer,.ReactVirtualized__Table__rowColumn,.ReactVirtualized__Table__row
        overflow: visible !important
    .custom_render_taskCField.custom_select
         .custom_select_header
            margin-top: 0px
            .custom_select_border
                border: 0px
    .custom_render_taskCField_short
        padding: 0px
        height: 25px
        width: 25px
        &.opened .custom_select_color_ball 
            border-radius: 15px 15px 0 0
        .custom_select_header
            height: 25px
            .custom_select_header_text
                height: 25px
                .custom_select_header_container
                    position: relative
                    height: 25px
                    .custom_select_color_ball 
                        margin: 0
                        height: 25px
                        width: 25px
                    .custom_select_item_text
                        color: var(--whiteC)
                        position: absolute
                        top: 3px
                        left: 0
                        text-align: center
                        bottom: 0
                        right: 0
        .custom_select_header_icon svg,.custom_select_border,.custom_select.custom_select_label_up label 
            display: none
        .custom_select_header_container .custom_select_block_icon
            display: block
            left: 7px
        .custom_select_dropdown
            top: calc(100%)
            margin: 0 -15px
            .ReactVirtualized__Grid 
                padding: 0 15px
                box-shadow: none
                background: none
                overflow-x: hidden !important
                .ReactVirtualized__Grid__innerScrollContainer
                    width: 25px !important
            .ReactVirtualized__List
                border-radius: 0px 0px 10px 10px
        .custom_select_value 
            padding: 0px
            position: relative
            .custom_select_color_ball
                margin: 0px
                border-radius: 0
                width: 100%
                height: 100%
            .custom_select_item_row_text
                position: absolute
                color: var(--whiteC)
                left: 0
                right: 0
                text-align: center
.editcardtabs_tab
    .taskDoubleTable
        .task_screen_table
            width: 100% !important
.custom_select.custom_select_label_up label 
    min-height: 19px

@keyframes expandGrid
    0%
        grid-template-columns: 1fr 0fr
    100%
        grid-template-columns: minmax(600px, 6fr) minmax(500px, 5fr)
        
@keyframes hideItem
    0%
        width: 0px
        opacity: 0
    90%
        width: 0px
        opacity: 0
    91%
        width: 100%
        opacity: 0
    100%
        width: 100%
        opacity: 1

@media (max-width: 1600px)
    .taskDoubleTable.active .task_table_title
        width: 200px
@media (max-width: 1400px)
    .taskDoubleTable
        padding: 20px 25px
        .task_table_wb 
            padding: 20px 30px
        .single_task_inner
            padding: 15px 20px
        .single_task_header
            padding: 10px 20px
@media (max-width: 1205px)
    .taskDoubleTable
        .load_button_filter > .button_secondary 
            .load_filters_button_text
                display: none
            .load_filters_button_icon
                display: block
                margin-right: 8px
        .load_columns_button_text
            display: none
        
