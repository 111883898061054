.white_box_calendar_events
    .white_box_calendar_events_wrapper
        overflow: auto
        .whitebox_calendar_events_big_calendar
            min-width: 300px
.white_box_calendar_events .rbc-calendar .calendar_toolbar
    display: none
@media (max-width: 1805px)
    .white_box_calendar_events .custom_year
        display: flex
        flex-wrap: wrap
        .calendar_month_item
            min-width: 250px
            max-width: 300px
            width: 100%
@media (max-width: 690px)
    .white_box_calendar_events .wcal_event_container
        min-width: 250px