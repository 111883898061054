.modal
  transition: 0.3s
  right: 0
  transform: translateX(100%)
  &.active
    transform: initial
  .modal_negative_margin
    margin: 0 -30px
    & > .overflow-auto
      padding: 0 30px
.modal_extrabig
  width: 1200px
  bottom: 0px
  padding: 47px 78px
.modal_big
  width: 992px
  bottom: 0px
  padding: 47px 78px
.modal_tabs
  width: 1200px
  bottom: 0px
  .editcardtabs_leftbar 
    background: #F9F9F9
  .modal_tab_inner
    padding: 47px 50px
.modal_medium
  width: 784px
  bottom: 0
  padding: 47px 80px
.modal_small_medium
  width: 610px
  bottom: 0
  padding: 47px 80px
.modal_little
  width: 500px
  bottom: 0
  padding: 47px 80px
.modal_smallcentered, .modal_mediumcentered
  transform: initial
  opacity: 0
  right: initial
  width: 567px
  height: 282px
  padding: 45px 75px
  border-radius: 16.5px
  box-shadow: 0 5px 15px 0 rgba(0,0,0,0.35)
  text-align: center
  &.small_padding
    padding: 35px 45px
  &.big_small_centered
    height: 350px
    .input_field_label_margin .input_field_with_label  
      margin-top: 16px
  &.active
    opacity: 1
  .input_field
    max-width: 350px
    width: 100%
    margin: 0 auto
.close_modal
  margin-bottom: 30px
  .icon_close_modal
    margin-right: 15px
.modal_mediumcentered
  height: auto
  max-height: 340px
  padding: 25px 75px

@media (max-width: 1200px)
    .modal
        width: 100%
        .editcardtabs_leftbar
            width: auto
        .form-renderer
            div
                .grid-cols-3
                    grid-template-columns: repeat(3, minmax(0, 1fr))
                    .col-span-3
                        grid-column: span 3 / span 3
@media (max-width: 720px)
    .modal
        .editcardtabs_leftbar 
            .ectlb_tabs
                .editcardtabs_tabitem
                    font-size: 14px
        .form-renderer
            div
                .grid-cols-3
                    grid-template-columns: repeat(1, minmax(0, 1fr))
                    .col-span-3
                        grid-column: span 1 / span 1

@media (max-width: 530px)
  .modal.modal_tabs
      padding: 0
      .modal_tab_inner
          padding: 47px 16px
      .editcardtabs_leftbar
          padding-left: 5px

@media (max-width: 500px)
  .modal
    padding: 20px
    .modal_negative_margin
      margin: 0 -10px
      & > .overflow-auto
        padding-left: 10px
        padding-right: 10px
  