.list_adder
    border-radius: 15px
    border: 1px solid var(--grayD6)
    min-height: auto
    height: auto
    padding: 10px
    padding-bottom: 0px
    .list_adder_item
        margin: 0
        margin-right: 10px
        margin-bottom: 10px
        padding: 5px 8px 
        background-color: var(--grayDD)
        border-radius: 5px
.list_adder_filters .input_field_with_label
    flex: 1 1 auto